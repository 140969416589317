const addedMasks = [];
const addMasks = (parent, selector = '.js-input-tel', maskType = 'phone') => {
    const masks = parent.querySelectorAll(selector);
    masks.forEach((elem) => {
        const ind = addedMasks.findIndex(([e]) => e === elem);
        if (ind === -1) {
            return;
        }
        const [, mask, listeners] = addedMasks[ind];
        mask.destroy();
        Object.entries(listeners).forEach(([event, listener]) => elem.removeEventListener(event, listener));
        addedMasks.splice(ind, 1);
    });
    const added = [];
    masks.forEach((elem) => {
        const optsByType = {
            phone: {
                mask: '+{7}(f00)000-00-00',
                definitions: {
                    'f': /[1-69]/
                },
            },
            date: {
                mask: Date
            }
        }
        const opts = optsByType[maskType] ?? optsByType.phone;
        const masked = IMask.createMask(opts);
        const mask = IMask(
            elem,
            {
                ...{
                    lazy: true,
                    //overwrite: true,
                },
                ...opts
            },
        );
        mask.on('accept', () => {
            mask.value = masked.resolve(mask.value);
        });
        const listenersByType = {
            phone: {
                focus: () => {
                    mask.updateOptions({ lazy: false });
                },
                blur: () => {
                    if (!mask.masked.isComplete) {
                        mask.value = '';
                    }
                    mask.updateOptions({ lazy: true });
                },
                input: (e) => {
                    if (mask._changingCursorPos === 16 && !mask.masked.isComplete) {
                        mask.value = masked.resolve(`${mask.value}${e.data}`);
                    }
                },
            },
            date: {
                focus: () => {
                    mask.updateOptions({ lazy: false });
                },
                blur: () => {
                    if (!mask.masked.isComplete) {
                        mask.value = '';
                    }
                    mask.updateOptions({ lazy: true });
                }
            }
        };
        const listeners = listenersByType[maskType] ?? listenersByType.phone;
        if (maskType === 'phone') {
            elem.placeholder = '+7(___) ___-__-__';
        }
        Object.entries(listeners).forEach(([event, listener]) => {
            elem.addEventListener(event, listener)
        });
        addedMasks.push([elem, mask, listeners]);
        added.push(elem);
    });
    return added;
}
window.addMasks = addMasks;
window.addedMasks = addedMasks;

document.addEventListener('DOMContentLoaded', () => {
    window.addMasks(document);
});

var no_async = 0;
var width = Math.max($(window).width(), window.innerWidth);
var height = window.innerHeight;
var datepicker = [];
var timeslider = [];
var partner;

$(window).on('load', load);
$(document).ready(document_ready);

function load()
{
	partner = $('body').data('partner');
	ancor = function () {
		var offset = $($(this).attr("href")).offset();
		$("body, html").animate({"scrollTop":offset.top},'slow');
		return false;
		}


	$('form.need_check_form').each(function() {check_form($('#'+$(this).attr('id')), 0);});
	$('body').on('submit', 'form.need_check_form', function() {return check_form($('#'+$(this).attr('id')),1);});
	$('body').on('focus', '.need_field', function() {window.ready_field(this);});
	$('form.need_check_form .check_field').parent().find('.select-styled').on('click', function() {window.ready_field($(this).parent().find('.check_field'));});
	$('body').on('onblur', 'form.need_check_form .check_field', function() {window.check_field(this);});
	$('body').on('focus', 'form.need_check_form .check_field', function() {window.ready_field(this);});

	$('body').on('click', '.filter_reset_button', function() {clear_search_filter(this);});
	$('body').on('click', '.filter_form_button', function() {return check_common_search_form($('#'+$('form.common_search_form').attr('id')), 1);});
	$('body').on('submit', 'form.common_search_form', function() {return check_common_search_form($('#'+$(this).attr('id')), 1);});
	$('body').on('change', '.feature_value', function() {return check_common_search_form($('#'+$('form.common_search_form').attr('id')), 0);});
	$('body').on('change', '.brand_value', function() {return check_common_search_form($('#'+$('form.common_search_form').attr('id')), 0);});
    $('body').on('change', '.category_value', function () {
        return check_common_search_form($('#' + $('form.common_search_form').attr('id')), 0);
    });
    $('body').on('click', '.js-add-more-btn', function () {
        return check_common_search_form($('#' + $('form.common_search_form').attr('id')), 0, true);
    });
	$('body').on('change', '.price_from', function() {return check_common_search_form($('#'+$('form.common_search_form').attr('id')), 0);})
	$('body').on('change', '.price_to', function() {return check_common_search_form($('#'+$('form.common_search_form').attr('id')), 0);})
	$('body').on('click', '.filter-button', function()
	{
		var id_list = $(this).attr('id').split('_');
		if (id_list[2] == 'brand') {$('.brand_'+id_list[3]).prop('checked', false);}
		if (id_list[2] == 'category') {$('.category_'+id_list[3]).prop('checked', false);}
		if (id_list[2] == 'feature') {$('.feature_value_feature_'+id_list[3]+'_value_'+id_list[5]).prop('checked', false);}
		return check_common_search_form($('#'+$('form.common_search_form').attr('id')), 0);
	})

	$('.js-popup-link.new_popup').unbind('click');
	$('body').on('click', '.js-popup-link.new_popup', check_popup);

	$('.no_first_show:not(.no_show)').removeClass('nod');

    /*$('body').on('click', '.partner_landing_catalog_list', function () {
        ga('ec:setAction', 'add');
        ga('send', 'event', 'UX', 'click', partner + '_step0');

        var data = {
            'event': 'vntArent',
            'eventCategory': 'arenter',
            'eventAction': 'button_click',
            'eventLabel': 'arendovat',
            'eventValue': null,
            'userAuth': 0,
            'screenName': '/',

            'eventContent': null,
            'eventContext': null,
            'buttonLocation': 'screen',
            'filterName': null,
            'actionGroup': 'interactions',
            'productName': $(this).parents('.optional_product_wrapper').find('.item_name').val().replace("'", ""),
            'productId': $(this).parents('.optional_product_wrapper').find('.partner_item_id').val().replace("'", ""),
            'touchPoint': 'web'
        };
        if ($('.user_id').html()) {
            data.userAuth = 1;
        }

        dataLayer.push(data);

        return true;
    });*/

	$('body').on('click', '.partner_order', function() {
		var id = $(this).attr('id');
		var list = id.split('_');
		try {setCookie(partner+'_phone_order', list[2]);} catch {}
		window.location.reload();
	});
    // check_common_search_form($('#'+$('form.common_search_form').attr('id')), 0);

    $('.inputbox__input').on('input', function() {
        $inputField = $(this).closest('.inputbox__field');
        if($(this).val() !== '') {
            $inputField.addClass('is-filled');
        } else {
            $inputField.removeClass('is-filled');
        }
    })
    $('.js-clear-field').on('click', function() {
        $(this).siblings('input').val('');
        $(this).closest('.inputbox__field').removeClass('is-filled');
    });
    $('.js-toggle-password').on('click', function() {
        const input = $(this).siblings('input');
        if(input.attr('type') === 'text') {
            input.attr('type', 'password');
        } else {
            input.attr('type', 'text');
        }
    });

    $('.js-popup-open').on('click', function(e) {
        e.preventDefault();

        if($(this).closest('.pop').length) {
            $(this).closest('.pop').removeClass('is-active');
        }
        $($(this).attr('href')).addClass('is-active');
    });

    $('.js-popup-close').on('click', function(e) {
        e.stopPropagation();
        $(this).closest('.popup').removeClass('is-active');
    });

    let flyTimerId;
    const flyDebounce = (fn, delay) => {
        clearTimeout(flyTimerId);
        flyTimerId = setTimeout(fn, delay);
    }
    $('body').on('keyup input focus', '.popup-search__form .searchform .inputbox__input', () => flyDebounce(() => fly_search(), 500));
}

function document_ready () {
    $('body').on('click', '.popup-city .radiobutton__input', function(e) {
        const current = $(this).closest('form').data('current');
        if ($(this).val() == current) {
            $('#popup-2city').find('.js-popup-close').trigger('click');
            return;
        }
        $.ajax({
            url: '/city/',
            type: 'post',
            data: { city_id: $(this).val(), host: document.location.hostname },
            dataType: 'json',
            complete: function (data) {
                if (data.status !== 200) {
                    return;
                }
                document.location.reload();
            }
        })
    });
}

const flyResults = {};
let flyAjax;
function fly_search() {
    const landingPrefix = $('.popup-search__form .searchform form').data('action');
    const search = $('.popup-search__form input[name="search"]').val();
    const renderSearchResults = ({ html }) => {
        $('.popup-search__catalog').html(html);
    };
    if (flyAjax) {
        flyAjax.abort();
    }
    if (search.length < 2) {
        renderSearchResults({ html: '' });
        return;
    }
    if (flyResults[search]) {
        renderSearchResults(flyResults[search]);
        return;
    }
    const ajax_obj = {
        url: `${landingPrefix}search/`,
        type: 'post',
        data: { search },
        dataType: 'json',
        complete: function ({ status, responseJSON }) {
            if (status !== 200) {
                return;
            }
            flyResults[search] = responseJSON;
            renderSearchResults(flyResults[search]);
        },
        cache: false,
        async: true
    };

    flyAjax = $.ajax(ajax_obj);
}

function clear_search_filter(elem)
{
	if (elem)
	{
		$('.filter-hint').addClass('nod');
		var form = $(elem).parents('form');
		var name=$(form).find('.search_name').val();
		//$(form).find('.filter_info').addClass('nod');
		$(form).find('.filter_result_wrapper').html('');
		$(form).find('.feature_value').prop('checked', false);
		$(form).find('.feature_value').removeAttr('checked');
		$(form).find('.search_item_wrapper').removeClass('active');
		$(form).find('.price_to').val($('.filter_price_line').attr('data-max').replace(/[^0-9]/gi, ""));
		$(form).find('.price_from').val($('.filter_price_line').attr('data-min').replace(/[^0-9]/gi, ""));

		delCookie(name);
		//console.log(document.cookie);
		//window.location.reload();
		check_common_search_form(form, 0);
	}
}

function check_popup(e)
{

	//e.preventDefault();

	var id = $(this).attr('href');
	if (id)
	{
		if ($(this).hasClass('link_phone')) {id='#popup-callback';}
		var intitiator = this;
		if ($(this).hasClass('reload_popup')) {$(id).remove();}
		if ($(id).length > 0) {show_popup($(id));}
		if ($(id).length == 0)
		{
			data = new Object();
			data.id = id.replace('#', '');
			$(this).find('.add_field').each(function() {
				data[$(this).attr('name')]=$(this).val();
				});

			if ($(this).find('.fields_wrapper').val())
			{
				var fields = '.add_class';
				if ($(this).find('.fields_class').val()) {fields = $(this).find('.fields_class').val();}
				var data = get_values($($(this).find('.fields_wrapper').val()), fields, data);
			}

			var ajax_obj = {
				url: '/show_popup/',
				type: 'post',
				beforeSend: function() {},
				data: data,
				dataType:'json',
				complete: function(data) {
					if (data.responseText)
					{
						var res;
						try {res = eval('('+data.responseText+')');}
						catch(e) {res = new Object;}
						if (res.html)
						{
							if ($(id).length == 0)
							{
							var ready = 0;
							if ($(intitiator).hasClass('put_next')) {ready = 1;$(intitiator).after(res.html);}
							if (ready == 0) {ready = 1;$('body').append(res.html);}
							if (ready == 1) {}
                                window.addMasks(document);
							//$(".js-input-tel").mask("+7(999) 999-99-99");
							show_popup($(id));
							}
							$('.popup').find('.selectstyle').each(function(i) {
								update_select_style(this);
								$('.cart_param_list').on('click', function() {return set_cart_param(this);});
							});
						}
					}
				},
				cache: false,
				async: false
				};

			$.ajax(ajax_obj);
		}
	}
	return false;
}

function show_popup(elem)
{
	if (elem)
	{
		var id = $(elem).attr('id');
		if (id)
		{
			var btn = $('a[href="#'+id+'"');
			if ($(btn).hasClass('prolong_button'))
			{
				var offset = btn.offset();
				btn.toggleClass('is-open')


				if(Math.max($(window).width(), window.innerWidth) > 450){
					var top = offset.top + btn.outerHeight() + 2;
					var left = offset.left - 375 + btn.outerWidth();
				}else{
					var top = offset.top + btn.outerHeight() + 2;
					var left = 12;
				}
				$(elem).css({top:top,left:left});
				$(elem).fadeToggle(200);

			}
			else
			{
				$(elem).fadeIn(400);
				$('body').addClass('is-popup-open');
				if ($(elem).find('.product-subs__offer').length > 0)
				{
					equalheight('.product-subs__offer-info');
					equalheight('.product-subs__offer-term');
				}
			}
		}
	}
}


function pageX(elem) {
    if (elem) {
        return elem.offsetParent ? elem.offsetLeft + pageX(elem.offsetParent) : elem.offsetLeft;
    }
}

// Определение координаты элемента по вертикали

function get_top(elem) {
    if (elem) {
        return elem.offsetParent ? elem.offsetTop + get_top(elem.offsetParent) : elem.offsetTop;
    }
}


function set_order_value(elem) {
    if (elem) {
        if ($(elem).parents('.order_column_wrapper').find('.order_name').val()) {
            cookie_name = $(elem).parents('.order_column_wrapper').find('.order_name').val();
        }
        var desc = getCookie(cookie_name + '_desc');
        var column = getCookie(cookie_name);
        var new_order = $(elem).attr('id');
        setCookie(cookie_name, new_order);
        if (!desc) {
            desc = 'desc';
        }
        new_desc = desc;
        if (new_order == column) {
            var new_desc = 'asc';
            if (desc == 'asc') {
                new_desc = 'desc';
            }
        }

        setCookie(cookie_name + '_desc', new_desc);
        window.location.reload();
    }
}

const options = {
    rootMargin: '0px',
    threshold: 1.0,
}

const observer = new IntersectionObserver((entries, observer) => {
    const [{ isIntersecting }] = entries;
    const isAutoload = $('.js-add-more-btn button').hasClass('autoload');
    if (isIntersecting && isAutoload) {
        $('.js-add-more-btn button').click();
    }
    //console.log('callback');
}, options);
let inited = false;

window.check_common_search_form = function (elem, reload, more = false) {
    if (!elem) {
        return false;
    }
    var name = $(elem).find('.search_name').val();

    if (!name) {
        return false;
    }
    var value = '';
    var new_val = [];
    i = 0;

    //$('.filter_loader').removeClass('nod');
    $('.common_result_count').html($('.filter_loader .loader_wrapper').html());
    //return false;
    $('.filter_result_wrapper').html('');
    //$('.filter_info').addClass('nod');
    var good = 0;
    $('.brand_value').each(function () {
        if ($(this).prop('checked')) {
            if (!new_val[i]) {
                new_val[i] = 'brand';
            }
            new_val[i] = new_val[i] + ':' + $(this).val();
            $('.filter_button_brand_' + $(this).val()).removeClass('nod');
            good = 1;
        } else {
            $('.filter_button_brand_' + $(this).val()).addClass('nod');
        }
    });
    if (good == 1) {
        i++;
    }

    good = 0;
    $('.category_value').each(function () {
        if ($(this).prop('checked')) {
            if (!new_val[i]) {
                new_val[i] = 'partner_parent_category';
            }
            new_val[i] = new_val[i] + ':' + $(this).val();
            $('.filter_button_category_' + $(this).val()).removeClass('nod');
            good = 1;
        } else {
            $('.filter_button_category_' + $(this).val()).addClass('nod');
        }
    });
    if (good == 1) {
        i++;
    }

    $('.feature_wrapper').each(function () {
        var list = $(this).find('.search_item_wrapper');

        if (!list.length) {
            return;
        }
        good = 0;
        for (k = 0; k < list.length; k++) {
            var check = $(list[k]).find('.feature_value');

            if ($(check).prop('checked')) {
                if (!new_val[i]) {
                    new_val[i] = 'feature:' + $(this).find('.feature_id').val();
                }
                new_val[i] = new_val[i] + ':' + $(list[k]).find('.feature_value').val();
                good = 1;
                var name = $(list[k]).find('.feature_value').val();
                if ($(check).find('.value_descr').val()) {
                    name = $(check).find('.value_descr').val();
                }

                $('.filter_button_feature_' + $(this).find('.feature_id').val() + '_value_' + $(list[k]).find('.value_id').val()).removeClass('nod');
            } else {
                $('.filter_button_feature_' + $(this).find('.feature_id').val() + '_value_' + $(list[k]).find('.value_id').val()).addClass('nod');
            }
        }
        if (good == 1) {
            i++;
        }
    });
    $('.filter_dot_button').removeClass('is-active');

    if ($(elem).find('.price_from').val() && $(elem).find('.price_from').val().replace(/[^0-9]/gi, "") != $('.filter_price_line').attr('data-min').replace(/[^0-9]/gi, "")) {
        new_val[i] = 'price_from:' + $(elem).find('.price_from').val().replace(/[^0-9]/gi, "");
        i++;
    }
    if ($(elem).find('.price_to').val() && $(elem).find('.price_to').val().replace(/[^0-9]/gi, "") != $('.filter_price_line').attr('data-max').replace(/[^0-9]/gi, "")) {
        new_val[i] = 'price_to:' + $(elem).find('.price_to').val().replace(/[^0-9]/gi, "");
    }
    if (new_val.length > 0) {
        $('.filter_reset_button').removeClass('nod');
    } else {
        $('.filter_reset_button').addClass('nod');
    }
    if (new_val.length > 0) {
        value = new_val.join("|").replace('+', 'pppplus');
        setCookie(name, value);
    } else {
        delCookie(name);
    }
    if (reload == 1) {
        window.location.reload();
        return;
    }
    var full_html = $('.filter_result_wrapper').html();
    //no_async = 1;
    var data = {};

    var searchParams = new URLSearchParams(window.location.search);
    data.page = !inited ? (searchParams.get('page') || 1) : 1;
    if (more) {
        data.page = Number($('.js-add-more-btn button').data('currentPage')) + 1;
    }

    observer.unobserve(document.querySelector('.js-pagination_wrapper'));

    if (more) {
        data.beforeSend = () => {
            $('.js-add-more-btn button').removeClass('nod');
            $('.js-add-more-btn button').html('<img src="/assets/images/loader.gif" class="loader">');
        };
    }

    data.afterSend = (answer) => {
        inited = true;
        if (more) {
            $('.js-add-more-btn button').addClass('nod');
        } else {
            //console.log('unhide');
            $('.js-add-more-btn button').removeClass('nod');
        }
        $('.filter_loader').addClass('nod');
        $('.filter_result_wrapper').html(full_html);

        if (answer.responseText) {
            try {
                res = $.parseJSON(answer.responseText)
            } catch (e) {
                var res = {};
                res.status = 'Success';
            }

            /*if (res.total > 0) {
                $('.product_wrapper').removeClass('nod');
            } else {
                $('.product_wrapper').addClass('nod');
            }*/
            if (res.count_html) {
                $('.filter_form_button').html(res.count_html);
            }
            if (res.product_filter) {
                $('.catalog__leftside').html(res.product_filter);
                if($('.js-price-slider').length) {
                    window.initPriceSlider($('.js-price-slider'));
                }
            }
            if (res.content) {
                if (!more) {
                    $('.product_wrapper').html(res.content);
                } else {
                    const virtualContent = document.createElement('div');
                    virtualContent.innerHTML = res.content;
                    const availablePart = virtualContent.querySelector('[data-section="available"]');
                    if (availablePart) {
                        const currentAvailablePart = $('.product_wrapper').find('[data-section="available"]');
                        if (currentAvailablePart.length) {
                            const grid = availablePart.querySelector('.product-grid');
                            currentAvailablePart.find('.product-grid').first().append(grid.innerHTML);
                        } else {
                            $('.product_wrapper').append(availablePart.outerHTML);
                        }
                    }
                    const in7DaysPart = virtualContent.querySelector('[data-section="in_7_days"]');
                    if (in7DaysPart) {
                        const currentIn7DaysPart = $('.product_wrapper').find('[data-section="in_7_days"]');
                        if (currentIn7DaysPart.length) {
                            const grid = in7DaysPart.querySelector('.product-grid');
                            currentIn7DaysPart.find('.product-grid').first().append(grid.innerHTML);
                        } else {
                            $('.product_wrapper').append(in7DaysPart.outerHTML);
                        }
                    }
                    const notAvailablePart = virtualContent.querySelector('[data-section="non_available"]');
                    if (notAvailablePart) {
                        const currentNotAvailablePart = $('.product_wrapper').find('[data-section="non_available"]');
                        if (currentNotAvailablePart.length) {
                            const grid = notAvailablePart.querySelector('.product-grid');
                            currentNotAvailablePart.find('.product-grid').first().append(grid.innerHTML);
                        } else {
                            $('.product_wrapper').append(notAvailablePart.outerHTML);
                        }
                    }
                }
            }
            if (res.total_items_html) {
                $('.total_items_html').html(res.total_items_html);
            }
            if (res.paginate !== undefined) {
                $('.js-pagination_wrapper').html(res.paginate)
            }
            if (res.showMore) {
                $('.js-add-more-btn button').addClass('autoload').data('currentPage', res.currentPage).text(`Показать ещё`);// ${res.showMore}`);
            } else {
                $('.js-add-more-btn button').addClass('nod').removeClass('autoload');
            }
            if (more) {
                //console.log('observe');
                observer.observe(document.querySelector('.js-pagination_wrapper'));
            } else {
                //console.log('remove autoload');
                $('.js-add-more-btn button').removeClass('autoload');
            }

            $('.catalog__filters').removeClass('has-selected');
            if (new_val.length > 0) {
                $('.filter_dot_button').addClass('is-active');
                $('.catalog__filters').addClass('has-selected');
            }

            const loc = window.location.origin;
            const pn = window.location.pathname;
            searchParams.set('page', data.page);
            if (data.page <= 1) {
                searchParams.delete('page');
            }
            const addr = searchParams.toString() ? loc + pn + '?' + searchParams : loc + pn;
            window.history.replaceState({}, null, addr);
        }
    };
    data.useCode = false;
    no_async = 0;
    send_form($(elem), data, more);
    //no_async = 0;

    return false;
}

window.check_field = (elem, kod) => {
    var def = '';
    var ready = 0;
    var good = 1;

    if ($(elem).attr('type') == 'radio') {
        ready = 1;
        $(elem).parents('.group_wrapper').find('.result_marker').removeClass('is-error');
        var name = $(elem).attr('name');
        good = 0;

        $(elem).parents('form').find('input[name="' + name + '"]').each(function () {
            if ($(this).prop('checked')) {
                good = 1;
            }
        });
    }

    if ($(elem).attr('type') == 'checkbox') {
        ready = 1;
        $(elem).removeClass('is-error');
        if ($(elem).hasClass('need_field')) {
            if (!$(elem).prop('checked')) {
                good = 0;
            }
        }
    }

    if (ready == 0) {
        if ($(elem).attr('default_value')) {
            def = $(elem).attr('default_value');
        }
        if ($(elem).attr('default')) {
            def = $(elem).attr('default');
        }
        if ($(elem).parent().find('.default').html()) {
            def = $(elem).parent().find('.default').html();
        }
        $(elem).parent().find('.default').addClass('nod');
        if ($(elem).val() == '') {
            good = 0;
        }
    }


    if (good == 0) {
        $(elem).parent().find('.default').removeClass('nod');
        if ($(elem).hasClass('is-error')) {
            $(elem).parent().find('.select-styled').addClass('is-error');
        }
        if ($(elem).hasClass('need_field') && kod == 1) {
            $(elem).addClass('is-error');
            $(elem).parent().find('.select-styled').addClass('is-error');
            $(elem).parents('.group_wrapper').find('.result_marker').addClass('is-error');
            return false;
        }
    } else {
        return true;
    }
}

function clear_form(elem) {
    $(elem).find('.add_field').each(function () {
        if ($(this).hasClass('no_clear') == false) {
            $(this).val('');
        }
    });
    check_form(elem);
}

function get_code(rand)
{
	var req = new Object;
	var result;
	if (rand) {req.rand = rand;}
	var ajax_obj = {
		url: '/form/code/',
		type: 'post',
		data: req,
		dataType:'json',
		complete: function(result)
		{
			if (result.responseText)
			{
				try {res = $.parseJSON(result.responseText);}
				catch (err)
				{
					res = new Object();
					res.result = 1;
					res.message = 'Данные отправлены';
				}

				if (res.code) {req.code = res.code;}
			}
		},
		cache: false,
		async: false
	};
	$.ajax(ajax_obj);

	return req.code;
}

function get_values(elem, fields, data) {
    var arr = new Object;
    if (!data) {
        var data = new Object;
    }
    if (elem && fields) {
        $(elem).find(fields).each(function () {
            good = 1;
            if ($(this).attr('type') == "radio" && !$(this).prop('checked')) {
                good = 0;
            }
            if ($(this).attr('type') == "checkbox" && !$(this).prop('checked')) {
                good = 0;
            }
            if (good == 1) {
                if (data[$(this).attr('name')]) {
                    if (data[$(this).attr('name')] instanceof Array) {
                        data[$(this).attr('name')][data[$(this).attr('name')].length] = $(this).val();
                    } else {
                        var tmp = data[$(this).attr('name')];
                        data[$(this).attr('name')] = new Array;
                        data[$(this).attr('name')][data[$(this).attr('name')].length] = tmp;
                        data[$(this).attr('name')][data[$(this).attr('name')].length] = $(this).val();
                    }
                } else {
                    data[$(this).attr('name')] = $(this).val();
                }
            }
        });
    }
    return data;
}

$('body').on('focus', '.subscribe_form .mail_field', function() {
	$('.subscribe_form .success_wrapper').addClass('nod');
	$('.subscribe_form .error_wrapper').addClass('nod');
});

window.formSendAjax = {};
function send_form(elem, additionalData = {}, more) {
    if (!elem) {
        return false;
    }

    let data;
    if ($(elem).hasClass('full_send')) {
        try {
            data = new FormData(document.getElementById($(elem).attr('id')));
        } catch (err) {
            console.log(err);
        }
    } else {
        data = get_values(elem, '.add_field');
    }

    if ($(elem).hasClass('subscribe_form')) {
        $(elem).find('.success_wrapper').addClass('nod');
        $(elem).find('.error_wrapper').addClass('nod');
    }

    if (!data) {
        data = {};
    }
    data.from_page = document.location.href;
    if (additionalData.useCode ?? true) {
        data.rand = Math.random();
        data.code = get_code(data.rand);
    }
    additionalData = Object.fromEntries(Object.entries(additionalData).filter(([key]) => key !== 'useCode'));

    var url = $(elem).attr('action');
    if ($(elem).find('.url').val()) {
        url = $(elem).find('.url').val();
    }
    if (more) {
        url = url.replace(partner, `${partner}/more`);
    }

    const additionalScalarData = Object.entries(additionalData).filter(([, field]) => typeof field !== 'function');
    data = { ...data, ...Object.fromEntries(additionalScalarData) };

    var ajax_obj = {
        url,
        type: 'post',
        beforeSend: function () {
            $(elem).find('.result_wrapper').addClass('nod');
            if (!data.no_message) {
                $(elem).find('.result_wrapper').html('');
            }
            $(elem).find('.result_wrapper').removeClass('error-color');
            $(elem).find('.result_wrapper').removeClass('green');
            $(elem).find('.field_wrapper').addClass('nod');
            $(elem).find('.loader_wrapper').removeClass('nod');
            if (additionalData.beforeSend) {
                additionalData.beforeSend();
            }
        },
        data,
        dataType: 'json',
        complete: function (answer) {
            if (!answer.responseText) {
                return;
            }

            let res;
            try {
                res = $.parseJSON(answer.responseText);
            } catch (err) {
                res = { result: 0 };
                if (answer.status === 200) {
                    res.result = 1;
                    res.message = 'Данные отправлены';
                }
            }

            if (res.result) {
                var stop = 0;
                if (res.redirect) {
                    window.location.href = res.redirect;
                    stop = 1;
                }

                if (res.callback) {
                    if (res.nowait) {
                        eval(res.callback);
                    } else {
                        $(elem).find('.result_wrapper').removeClass('nod').removeClass('error-color');
                        if (!$(elem).find('.result_wrapper').hasClass('no_change')) {
                            $(elem).find('.result_wrapper').addClass('good-color');
                        }
                        if (res.message) {
                            $(elem).find('.result_wrapper').html(res.message);
                        }
                        $(elem).find('.loader_wrapper').html('').addClass('nod');
                        if (res.wait) {
                            wt = res.wait;
                        } else {
                            wt = 3000;
                        }
                        setTimeout(function () {
                            eval(res.callback);
                        }, wt);
                    }
                    stop = 1;
                }
                if (stop == 0) {

                    $(elem).find('.result_wrapper').removeClass('nod').removeClass('error-color').removeClass('good-color');

                    if (res.message) {
                        if (!res.no_change_color && !$(elem).find('.result_wrapper').hasClass('no_change')) {
                            $(elem).find('.result_wrapper').addClass('good-color');
                        }
                        $(elem).find('.result_wrapper').removeClass('nod');
                        if (!res.no_message && !$(elem).find('.result_wrapper').hasClass('no_change')) {
                            $(elem).find('.result_wrapper').html(res.message);
                        }

                        if ($(elem).hasClass('subscribe_form')) {
                            $(elem).find('.success_wrapper').removeClass('nod');
                            $(elem).find('.field_wrapper').removeClass('nod');
                            $(elem).find('.success_wrapper').html(res.message);
                        }
                    }

                    $(elem).find('.loader_wrapper').addClass('nod');

                    if (res.need_clear) {
                        clear_form(elem);
                    }
                    change_captcha_class('symbol_captcha');
                    if (res.hide_result) {
                        setTimeout(function () {
                            $(elem).find('.result_wrapper').addClass('nod')
                        }, 3000);
                    }

                    if (!res.hide_fields && !$(elem).hasClass('hide_fields')) {
                        setTimeout(function () {
                            //$(elem).find('.result_wrapper').addClass('nod')
                            $(elem).find('.field_wrapper').removeClass('nod');
                            if ($(elem).hasClass('profile_passport_form')) {
                                $(elem).find('.field_wrapper').addClass('nod');
                            }
                        }, 3000);
                    }
                }

                if (res.good_redirect) {
                    window.location.href = res.good_redirect;
                }

                if ($(elem).hasClass('common_search_form')) {
                    if (res.total_items) {
                        $('.common_result_count').html(res.total_items);
                    }
                    if (res.content) {
                        if (!$(elem).hasClass('take_easy')) {
                            $('.catalog_content').html(res.content);
                            $('.catalog_content').find('.selectstyle').each(function (i) {
                                update_select_style(this);

                            });
                            check_price_slider();
                        }
                    }
                }
            }

            if (!res.result) {
                const errorMessageArr = [];
                $(elem).find('.loader_wrapper').addClass('nod');
                $(elem).find('.result_wrapper').removeClass('nod').removeClass('good-color').addClass('error-color');
                if (res.error) {

                    for (let i = 0; i < res.error.length; i++) {
                        if (res.error[i].field !== 'hidden') {
                            if (res.error[i].error) {
                                errorMessageArr.push(res.error[i].error);
                            }


                            $(elem).find('input[name="' + res.error[i].field + '"]').addClass('is-error');
                            $(elem).find('error_' + res.error[i].field).addClass('is-error');
                            if (res.error[i].field === 'symbol') {
                                $(elem).find('input[name=' + res.error[i].field + ']').val('');
                                change_captcha_class('symbol_captcha');
                            }
                        }
                    }

                    error_message = errorMessageArr.join('<br>');
                }
                error_message = '';
                try {
                    error_message = res.error_message ?? error_message ?? '';
                } catch {
                }
                check_form(elem);

                go_to_error();

                if ($(elem).hasClass('subscribe_form')) {
                    $(elem).find('.error_wrapper').removeClass('nod');
                    $(elem).find('.field_wrapper').removeClass('nod');
                    $(elem).find('.error_wrapper').html(res.error);
                }

                $(elem).find('.result_wrapper').removeClass('nod').addClass('error-color').html(error_message);
                if (!res.hide_fields) {
                    setTimeout(function () {
                        $(elem).find('.field_wrapper').removeClass('nod');
                    }, 300);

                }
                if (additionalData.afterSend) {
                    additionalData.afterSend(answer);
                }
            }
        },
        cache: false,
        async: true
    };
    if (no_async == 1) {
        ajax_obj.async = false;
    }
    if ($(elem).hasClass('full_send')) {
        try {
            ajax_obj.processData = false;
            ajax_obj.contentType = false;
        } catch (err) {
        }
    }
    if (window.formSendAjax.abort) {
        window.formSendAjax.abort();
    }
    window.formSendAjax = $.ajax(ajax_obj);
    return false;
}

window.ready_field = function (elem) {
    $(elem).removeClass('is-error');
    $(elem).parent().find('.select-styled').removeClass('is-error');
    $(elem).parent().find('.default').addClass('nod');
    $(elem).parents('.group_wrapper').find('.result_marker').removeClass('is-error');
    $(elem).parents('.date-wrapper').find('.is-error').removeClass('is-error');
}

function go_to_error(elem) {
    var offset = $('.is-error:visible').offset();
    if (elem) {
        offset = $(elem).find('.is-error:visible').offset();
    }

    if (offset) {
        if (offset.top > 0) {
            $("body, html").animate({ "scrollTop": offset.top }, 'slow');
        }
    }
}

function check_form(elem, kod) {
    var result = true;
    $(elem).find('.check_field').each(function () {
        if (window.check_field(this, kod) == false) {
            result = false;
        }

    });


    if ($(elem).hasClass('profile_passport_form') && kod == 1) {
        if ($(elem).find('.error_pas_ser').val().length < 4 && $(elem).find('.error_pas_ser').hasClass('add_field')) {
            $(elem).find('.error_pas_ser').addClass('is-error');
            result = false;
        }
        if ($(elem).find('.error_pas_num').val().length < 6 && $(elem).find('.error_pas_num').hasClass('add_field')) {
            $(elem).find('.error_pas_num').addClass('is-error');
            result = false;
        }
    }
    if ($(elem).hasClass('cart_list_form') && $(elem).find('.company_check').val() == 1) {
        if (window.check_field($(elem).find('.inn'), kod) == false) {
            result = false;
        }
        if (window.check_field($(elem).find('.kpp'), kod) == false) {
            result = false;
        }
        if (window.check_field($(elem).find('.ogrn'), kod) == false) {
            result = false;
        }
        if (window.check_field($(elem).find('.ur_address'), kod) == false) {
            result = false;
        }
    }
    if (result == false) {
        go_to_error(elem);
    }
    if (result == true) {


    }

    if (result == true && kod == 1) {
        if ($(elem).hasClass('ajax_form')) {

            result = false;
            if (!$(elem).hasClass('no_send_form')) {
                send_form(elem);
            }

        }

        if ($(elem).hasClass('no_send_form')) {
            result = false;
        }
    }

    return result;
}

function blur_field(element) {
    if (element) {
        if ($(element).val() == '') {
            $(element).val($(element).attr('default_value'));
        }
    }
}

function focus_field(element) {
    if (element) {
        $(element).removeClass('is-error').removeClass('light_gray');
        if ($(element).val() == $(element).attr('default_value')) {
            $(element).val('');
        }
    }
}

function get_scroll() {
    if (window.scrollY == undefined) {
        return parseInt(document.body.scrollTop);
    }
    return parseInt(window.scrollY);
}

function get_scroll_x() {
    if (window.scrollX == undefined) {
        return parseInt(document.body.scrollLeft);
    }
    return parseInt(window.scrollX);
}

function set_scroll(a) {
    if (window.scrollY == undefined) {
        document.body.scrollTop = a;
    } else {
        window.scrollY = a;
    }
}

function set_scroll_x(a) {

    if (window.scrollX == undefined) {

        document.body.Left = a;
        alert(document.body.Left);
    } else {
        window.scrollX = a;
    }
}

 function transkeybord(text, direction)
{
	var cyr = [
			'а','б','в','г','д','е','ж','з','и','й','к','л','м','н','о','п',
			'р','с','т','у','ф','х','ц','ч','ш','щ','ъ','ы','ь','э','ю','я',
			'А','Б','В','Г','Д','Е','Ж','З','И','Й','К','Л','М','Н','О','П',
			'Р','С','Т','У','Ф','Х','Ц','Ч','Ш','Щ','Ъ','Ы','Ь','Э','Ю','Я'
		];

		lat = [
			'f',',','d','u','l','t',';','p','b','q','r','k','v','y','j','g',
			'h','c','n','e','a','[','w','x','i','o',']','s','m','\'','.', 'z',
			'F','<','D','U','L','T',':','P','B','Q','R','K','V','Y','J','G',
			'H','C','N','E','A','{','W','X','I','O','}','S','M','"','>', 'Z'
		];

		var from = lat;
		var to = cyr;

		if (direction)
		{
			from = cyr;
			to = lat;
		}

		for (i = 0; i < from.length; i++)
		{

			text = text.replace(from[i], to[i]);
		}

		return text;
}

function only_rus_elem(elem)
	{
		var del_count=0;
		var pos=getCaretPos(elem);
		setCaretPos(elem,pos);
		var res='';
		res = $(elem).val();
		new_res = res;
		if ($(elem).hasClass('transkeybord')) {new_res = transkeybord(new_res);}
		new_res = new_res.replace(/[^а-яА-Я0-9\s]/gi, "");
		if (new_res!=res) {$(elem).val(new_res);del_count=res.length-new_res.length;}
		pos-=del_count;

		if (pos<0) {pos=0;}
		setCaretPos(elem,pos);

	return pos;
	}

function only_digit_elem(elem)
	{
		var del_count=0;
		var pos=getCaretPos(elem);
		setCaretPos(elem,pos);
		var res='';
		res = $(elem).val();
		new_res = res.replace(/[^0-9]/gi, "");
		if (new_res!=res) {$(elem).val(new_res);del_count=res.length-new_res.length;}
		pos-=del_count;

		if (pos<0) {pos=0;}
		setCaretPos(elem,pos);

	return pos;
	}

function only_digit(a)
	{

	if (document.getElementById(a).value)
		{
		var del_count=0;
		var pos=getCaretPos(document.getElementById(a));
		setCaretPos(document.getElementById(a),pos);
		var res='';
		res=document.getElementById(a).value.replace(/[^0-9]/gi, "");
		if (document.getElementById(a).value!=res) {document.getElementById(a).value=res;del_count=res.length-document.getElementById(a).value.length;}
		pos-=del_count;

		if (pos<0) {pos=0;}
		setCaretPos(document.getElementById(a),pos);
		}
	return pos;
	}

function only_digit2(a)
	{


	if (document.getElementById(a).value)
		{
		var del_count=0;
		var pos=getCaretPos(document.getElementById(a));
		setCaretPos(document.getElementById(a),pos);
		var res='';
		res=document.getElementById(a).value.replace(/[^0-9\s-()+]/gi, "");
		if (document.getElementById(a).value!=res) {document.getElementById(a).value=res;del_count=res.length-document.getElementById(a).value.length;}
		pos-=del_count;

		if (pos<0) {pos=0;}
		setCaretPos(document.getElementById(a),pos);
		}
	return pos;
	}

function only_digit3(a)
	{
	if (document.getElementById(a).value)
		{
		var del_count=0;
		var pos=getCaretPos(document.getElementById(a));
		setCaretPos(document.getElementById(a),pos);
		var res='';
		res=document.getElementById(a).value.replace(",", ".");
		res=res.replace(/[^0-9.-]/gi, "");
		if (document.getElementById(a).value!=res) {document.getElementById(a).value=res;del_count=res.length-document.getElementById(a).value.length;}
		pos-=del_count;

		if (pos<0) {pos=0;}
		setCaretPos(document.getElementById(a),pos);
		}
	return pos;
	}

function only_letter(a)
	{

	if (document.getElementById(a).value)
		{
		var del_count=0;
		var pos=getCaretPos(document.getElementById(a));
		setCaretPos(document.getElementById(a),pos);
		var res='';
		res=document.getElementById(a).value.replace(/[^a-zа-я]/gi, "");
		if (document.getElementById(a).value!=res) {document.getElementById(a).value=res;del_count=res.length-document.getElementById(a).value.length;}
		pos-=del_count;

		if (pos<0) {pos=0;}
		setCaretPos(document.getElementById(a),pos);
		}

	return pos;
	}

function prepareNumber(n)
	{
    var s = Number(n).toFixed(12);
    s = s.substr(0, s.length - 13);
    for (var i = s.length - 3, j = n < 0 ? 1 : 0; i > j; i -= 3) s = s.substr(0, i) + " " + s.substr(i);
    return s;
	}

function check_field_length(len, a)
	{
	len=parseInt(len);
	if (document.getElementById(a) && len>0)
		{
		var pos=getCaretPos(document.getElementById(a));
		if (!len) {len=200;}

		var text=$('#'+a).val();

		if (text.length>len)
			{
			text=text.substr(0,len);
			$('#'+a).val(text);
			setCaretPos(document.getElementById(a),pos);

			}
		else
			{

			}

		var more=len - text.length;
		$('#'+a+'_length').text(more);
		}
	}

function setCaretPos(obj, pos) {
    if (obj.createTextRange) {
        // IE
        var range = obj.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    } else if (obj.selectionEnd) {
        // Gecko
        obj.selectionEnd = pos;
    }
    return;
}

function getCaretPos(obj) {
    obj.focus();

    if (obj.selectionStart) return obj.selectionStart;//Gecko
    else if (document.selection)//IE
    {
        var selection = document.selection.createRange().getBookmark();
        var selection2 = obj.createTextRange();
        selection2.moveToBookmark(selection);
        var before = obj.createTextRange();
        before.setEndPoint('EndToStart', selection2);
        return before.text.length;
    }

    return 0;
}

function getCookie(name) {
    var cookie = " " + document.cookie;
    var search = " " + name + "=";
    var setStr = null;
    var offset = 0;
    var end = 0;
    if (cookie.length > 0) {
        offset = cookie.indexOf(search);
        if (offset != -1) {
            offset += search.length;
            end = cookie.indexOf(";", offset)
            if (end == -1) {
                end = cookie.length;
            }
            setStr = unescape(cookie.substring(offset, end));
        }
    }
    return (setStr);
}

function setCookie(name, value, no_expires) {
    var today = new Date();
    var expiration_past = new Date(today.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);
    var cookie = name + '=' + value + '; path=/;';
    if (!no_expires) {
        cookie += ' expires=' + expiration_past.toGMTString() + ';';
    }
    document.cookie = cookie;
}

function delCookie(name) {
    var today = new Date();
    var expiration_past = new Date(today.getTime() - 100 * 365 * 24 * 60 * 60 * 1000);  // 1 год назад
    document.cookie = name + '=delete; path=/; expires=' + expiration_past.toGMTString();
}



function change_captcha(a)
	{
	if (document.getElementById(a)) {
	$('.'+a+'_noload').addClass('nod');
	$('.'+a+'_load').removeClass('nod');
	var now = new Date().getTime();
	var src=$('#'+a).attr('src');

	arr=src.split('?');


	src=arr[0]+'?'+now;

	img = new Image();
	img.src=src;
	img.onload=function()
		{
		$('#'+a).attr('src',src);
		$('.'+a+'_load').addClass('nod');
		$('.'+a+'_noload').removeClass('nod');

		}
	}
	}

function change_captcha_class(a)
	{
	$('.'+a+'_noload').addClass('nod');
	$('.'+a+'_load').removeClass('nod');
	var now = new Date().getTime();
	var src=$('.'+a).attr('src');
	if (src)
		{
		arr=src.split('?');
		src=arr[0]+'?'+now;
		img = new Image();
		img.src=src;
		img.onload=function()
			{
			$('.'+a).attr('src',src);
			$('.'+a+'_load').addClass('nod');
			$('.'+a+'_noload').removeClass('nod');

			}
		}
	}


function AddToBookmark(url, title)
{

	if (!title) { var title = window.document.title; }
	if (!url) {var url = window.document.location; }

      try {
        // Internet Explorer
        window.external.AddFavorite(url, title);
      }
      catch (e) {
        try {
          // Mozilla
          window.sidebar.addPanel(title, url, "");
        }
        catch (e) {
          // Opera
          if (typeof(opera)=="object") {
            a.rel="sidebar";
            a.title=title;
            a.url=url;
            a.href=url;
            return true;
          }
          else {
            // Unknown
            alert('Нажмите Ctrl-D чтобы добавить страницу в закладки');
          }
        }
      }

   return false;
}

function getBrowserInfo() {
    var t,v = undefined;

    if (window.chrome) t = 'Chrome';
    else if (window.opera) t = 'Opera';
    else if (document.all) {
    t = 'IE';
    var nv = navigator.appVersion;
    var s = nv.indexOf('MSIE')+5;
    v = nv.substring(s,s+1);
    }
    else if (navigator.appName) t = 'Netscape';

    return {type:t,version:v};
    }

    function bookmark(a, title, url) {
  if (!title) {title=window.document.title; }
  if (!url) {url=indow.document.location; }

   var b = getBrowserInfo();
    alert(b.type);
    if (b.type == 'IE' && 8 >= b.version && b.version >= 4) window.external.AddFavorite(url,title);
    else if (b.type == 'Opera') {
    a.href = url;
    a.rel = "sidebar";
    a.title = url+','+title;
    return true;
    }
    else if (b.type == "Netscape") window.sidebar.addPanel(title,url,"");
    else alert("Нажмите CTRL-D, чтобы добавить страницу в закладки.");
    return false;
    }


function update_select_style(elem)
{
if (elem)
	{
	if (!$(elem).hasClass('select-hidden'))
	{

		var $this = $(elem), numberOfOptions = $(elem).children('option').length;

		$this.addClass('select-hidden');
		$this.wrap('<div class="select"></div>');
		$this.after('<div class="select-styled"><span></span></div>');

		var $styledSelect = $this.next('div.select-styled');
		$this.next('div.select-styled').find('span').text($this.children('option').eq(0).text());

		var $list = $('<ul />', {
			'class': 'select-options'
		}).insertAfter($styledSelect).wrap('<div class="select-options-wrap"></div>');

		for (var i = 0; i < numberOfOptions; i++) {
			$('<li />', {
				text: $this.children('option').eq(i).text(),
				rel: $this.children('option').eq(i).val()
			}).appendTo($list);
		}

		var $listItems = $list.children('li');

		$styledSelect.click(function(e) {
			e.stopPropagation();
			$('div.select-styled.active').not(this).each(function(){
				$(this).removeClass('active');
			});
			$(this).toggleClass('active');
			if($(this).hasClass('active'))
				$(this).next('.select-options-wrap').find('.select-options').jScrollPane();
			$('.js-selectbox-toggle').closest('.selectbox').removeClass('is-open');
		});

		$listItems.click(function(e) {
			e.stopPropagation();
			$(this).siblings().removeClass('is-selected');
			$(this).addClass('is-selected');
			$styledSelect.find('span').text($(this).text());
			$styledSelect.removeClass('active');
			$this.val($(this).attr('rel'));
			//console.log($this.val());
		});

		$list.find('li').eq($this.prop('selectedIndex')).click();

		$(document).click(function() {
			$styledSelect.removeClass('active');
		});
		set_select_trigger(elem);
	}


	}



}

function number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function set_select_trigger(elem)
	{
	if (elem)
		{
		$(elem).parents('.select').find('.select-options li').each(function() {
			$(this).on('click', function() {$(elem).trigger('change')});
			});
		}
	}

